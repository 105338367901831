// @ts-ignore
import React from "react"
import {  } from "gatsby"

const Home = () => {
  
  return <div>
      <img src="147476.jpg" />
      <img src="147476.png" />
      <img src="147476.png" />
      <img src="147476.png" />
      <img src="147476.png" />
    </div>
  
}

export default Home
